import { useState, FC } from 'react'
import Grid from '@mui/material/Grid'
import Popover from '@mui/material/Popover'
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'

import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  XIcon,
} from 'react-share'

import { Tooltip } from '@mui/material'
import { absoluteUrl } from '../../helpers/common'
import { useTranslation } from '../../providers/localesProvider'

type ComponentProps = IconButtonProps & {
  url?: string
  iconFontSize?: 'inherit' | 'medium' | 'small' | 'large'
}

const EventShare: FC<ComponentProps> = (props: ComponentProps) => {
  const { url = '', iconFontSize = 'medium', ...rest } = props
  const shareUrl = `${absoluteUrl().origin}${url}`
  props = rest
  const [anchorEl, setAnchorEl] = useState(null)
  const isMenuOpen = Boolean(anchorEl)
  const { t } = useTranslation()

  const handleProfileMenuOpen = (event: any): void => {
    if (event) setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = (): void => setAnchorEl(null)

  return (
    <>
      <Tooltip title={`${t('shareEventHint')}`}>
        <IconButton onClick={handleProfileMenuOpen} {...props} size="large">
          <ShareOutlinedIcon fontSize={iconFontSize} />
        </IconButton>
      </Tooltip>

      <Popover
        keepMounted
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Grid container spacing={1} style={{ overflow: 'hidden' }}>
          <Grid item>
            <FacebookShareButton url={shareUrl} onClick={handleMenuClose}>
              <FacebookIcon round size={48} />
            </FacebookShareButton>
          </Grid>
          <Grid item>
            <LinkedinShareButton url={shareUrl} onClick={handleMenuClose}>
              <LinkedinIcon round size={48} />
            </LinkedinShareButton>
          </Grid>
          <Grid item>
            <TwitterShareButton url={shareUrl} onClick={handleMenuClose}>
              <XIcon round size={48} />
            </TwitterShareButton>
          </Grid>
        </Grid>
      </Popover>
    </>
  )
}

export default EventShare
