import { useState, FC } from 'react'
import { Grid, Tooltip } from '@mui/material'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'

import { HexEvent } from '../../types'
import { useSnackBar } from '../../providers/SnackbarProvider'
import { useTranslation } from '../../providers/localesProvider'
import { useEvent, usePostFavoriteEvent } from '../../queryHooks/eventQuery'

import LoginDialog from '../login/LoginDialog'
import { useAuth } from '../../providers/authProvider'
import VerifyEmailLoginForm from '../login/VerifyEmailLoginForm'

type ComponentProps = IconButtonProps & {
  event: HexEvent
}

const EventFavorite: FC<ComponentProps> = (props: ComponentProps) => {
  const { event, size = 'large', ...iconButtonProps } = props
  const iconFontSize = 'small'

  const { data = {} as HexEvent } = useEvent({ eventUuid: event.uuid }, { initialData: event })

  const { t } = useTranslation()
  const { enqueue } = useSnackBar()
  const { isAuthenticated, isLimited } = useAuth()
  const { mutateAsync } = usePostFavoriteEvent()

  const [loginDialogOpen, setLoginDialogOpen] = useState(false)
  const handleLoginDialogOpen = (): void => setLoginDialogOpen(true)

  const handleMarkFavorite = async (data: HexEvent): Promise<void> => {
    if (isLimited() || !isAuthenticated()) {
      handleLoginDialogOpen()
      return
    }

    await mutateAsync(
      { isUserFavouriteEvent: !data.isUserFavouriteEvent, eventUuid: data.uuid },
      {
        onError(error) {
          enqueue({
            title: t('toggleFavoriteEventFailed'),
            body: error.message,
            severity: 'error',
          })
        },
      }
    )
  }

  const handleLoginDialogClose = async (openVerifiedDialog?: boolean): Promise<void> => {
    if (!openVerifiedDialog) {
      setLoginDialogOpen(false)
      if (isAuthenticated() && !isLimited()) await handleMarkFavorite(data)
    }
  }

  return (
    <>
      <Grid title={`${t('favoriteEventHint')}`} component={Tooltip}>
        <IconButton onClick={(): Promise<void> => handleMarkFavorite(data)} size={size} {...iconButtonProps}>
          {data.isUserFavouriteEvent ? (
            <FavoriteIcon fontSize={iconFontSize} />
          ) : (
            <FavoriteBorderIcon fontSize={iconFontSize} />
          )}
        </IconButton>
      </Grid>

      {isLimited() && isAuthenticated() ? (
        <VerifyEmailLoginForm open={loginDialogOpen} handleClose={handleLoginDialogClose} />
      ) : (
        <LoginDialog open={loginDialogOpen} handleClose={handleLoginDialogClose} isVerified />
      )}
    </>
  )
}

export default EventFavorite
