import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Avatar from '@mui/material/Avatar'
import { Box, Chip } from '@mui/material'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CardContent from '@mui/material/CardContent'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'

import { HexEvent } from '../../types'
import { getThump } from '../../helpers/utils'
import { useBrandQuery } from '../../queryHooks/metaQuery'
import { useTranslation } from '../../providers/localesProvider'

import Link from './ui/Link'
import Image from './ui/Image'
import EventShare from './EventShare'
import EventFavorite from './EventFavorite'
import { useDayJs } from '../../hooks/useDayJs'

const EventCard = ({ eventData, organizerPage = false }: { eventData: HexEvent; organizerPage?: boolean }) => {
  const { t } = useTranslation()
  const { data: ssrBrands } = useBrandQuery()
  const { format, formatRange, formatDateDayRange, monthAbbreviationFormat } = useDayJs()

  const eventImage = getThump(eventData)
  const { title, uriTitle, category, timeSettings, isShowOrganizerInEventPage, organizer, timeSlotsTimestampRange } =
    eventData

  const { isShowEndTime, isShowStartTime, timeZone, isUseLocalTimeZone } = timeSettings
  const eventStartTimeStamp = timeSlotsTimestampRange?.from ?? 0
  const eventEndTimeStamp = timeSlotsTimestampRange?.to ?? 0

  const eventTimeZone = !isUseLocalTimeZone ? timeZone?.standardName : undefined

  const eventDateDayFormat = formatDateDayRange({
    startTimeStamp: eventStartTimeStamp,
    endTimeStamp: eventEndTimeStamp,
    timeZone: eventTimeZone,
  })

  if (!eventData) return <></>

  return (
    <Grid container item justifyContent="center" xs={12} sm={6} md={organizerPage ? 6 : 4}>
      <Card
        sx={{
          height: 340,
          width: '100%',
          borderRadius: '16px',
          position: 'relative',
          '&:hover': {
            boxShadow: '0 0 30px 0 rgba(0,0,0,0.21)',
            [`& .imageCover`]: {
              marginTop: '-5px',
              transition: 'margin-top 0.25s ease',
            },
          },
        }}
      >
        <Grid
          className="imageCover"
          sx={{
            maxHeight: 165,
            position: 'relative',
            marginTop: 0,
            transition: 'margin-top 0.25s ease',
          }}
        >
          <Image
            src={eventImage}
            alt={eventData.title}
            style={{
              width: '100%',
              objectFit: 'cover',
            }}
            width={550}
            height={165}
          />
          <Box
            sx={{
              top: 0,
              position: 'absolute',
              p: 2,
              '& > .MuiButtonBase-root': {
                mr: 1,
                color: 'primary.main',
                backgroundColor: 'white',
                '&:hover': {
                  backgroundColor: 'rgba(255,255,255,0.8)',
                },
              },
            }}
          >
            {ssrBrands?.isDisableSocialShare ? null : (
              <EventShare size="small" iconFontSize="small" url={`/${uriTitle}`} />
            )}
            {ssrBrands?.isDisableFavorite ? null : <EventFavorite event={eventData} />}
          </Box>
          {eventStartTimeStamp ? (
            <Avatar
              sx={{
                minWidth: 60,
                width: 'auto',
                px: 1,
                height: 50,
                fontSize: 14,
                fontWeight: 800,
                borderRadius: 1,
                textAlign: 'center',
                boxSizing: 'border-box',
                backgroundColor: '#fff',
                border: '1px solid #E1E1E1',
                color: 'common.black',
                position: 'absolute',
                top: 15,
                right: 15,
                flexDirection: 'column',
              }}
            >
              <span style={{ flexWrap: 'nowrap', marginBottom: '4px' }}>{eventDateDayFormat}</span>
              <Grid sx={{ color: 'primary.main' }}>
                <span>
                  {format({ timeStamp: eventStartTimeStamp, format: monthAbbreviationFormat, timeZone: eventTimeZone })}
                </span>
              </Grid>
            </Avatar>
          ) : null}
          {category?.name ? (
            <Chip
              label={category.name}
              color="primary"
              sx={{ position: 'absolute', bottom: -10, left: 15, fontWeight: 700, borderRadius: 0.5 }}
            />
          ) : null}
        </Grid>

        <CardContent>
          <Grid container direction="column" spacing={0.7}>
            <Grid item sx={{ width: '100%' }} data-cy="eventDetails">
              <Link href={{ pathname: `/${uriTitle}` }} passHref>
                <Typography color="primary" sx={{ fontSize: 20, fontWeight: 'bold' }} title={title} noWrap>
                  {title}
                </Typography>
              </Link>
            </Grid>

            <Grid item sx={{ width: '100%' }}>
              <Typography noWrap sx={{ color: '#969696' }} title={eventData?.location?.fullAddress ?? ''}>
                {eventData?.location?.fullAddress}
              </Typography>
            </Grid>

            {isShowOrganizerInEventPage && eventData?.organizer?.uriTitle ? (
              <Grid container item alignItems="center" gap={0.5}>
                <Typography>{`${t('organizer')}: `}</Typography>

                <Link
                  href={{
                    pathname: `/organizers/${organizer.uriTitle}`,
                  }}
                  passHref
                >
                  <Typography color="primary" sx={{ fontWeight: 'bold', fontSize: 14 }}>
                    {eventData?.organizer?.name}
                  </Typography>
                </Link>
              </Grid>
            ) : null}

            <Grid container item sx={{ flexWrap: 'nowrap' }}>
              {eventStartTimeStamp || eventEndTimeStamp ? (
                <Grid container item sx={{ flexWrap: 'nowrap' }}>
                  <AccessTimeOutlinedIcon sx={{ mr: 0.5, mt: 0.2, fontSize: 17 }} />
                  <Grid>
                    <Typography sx={{ fontSize: 12 }} suppressHydrationWarning>
                      {formatRange({
                        startTimeStamp: eventStartTimeStamp,
                        endTimeStamp: eventEndTimeStamp,
                        isShowEndTime,
                        isShowStartTime,
                        timeZone: eventTimeZone,
                      })}
                    </Typography>
                  </Grid>
                </Grid>
              ) : null}

              <Grid sx={{ position: 'absolute', bottom: 10, right: 12 }}>
                <Link href={{ pathname: `/${uriTitle}` }} passHref>
                  <IconButton color="inherit" sx={{ backgroundColor: '#e3e3e3' }}>
                    <ArrowForwardIosIcon sx={{ fontSize: 16 }} />
                  </IconButton>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default EventCard
