import _throttle from 'lodash/throttle'
import { createRef, useEffect } from 'react'
import { emptyFunc } from '../helpers/common'

/**
 * Check if an element is in viewport

 * @param {function} callback
 * @param {number} offset - Number of pixels up to the observable element from the top
 * @param {number} throttleMilliseconds - Throttle observable listener, in ms
 */
export default function useVisibility<Element extends HTMLElement>(
  callback = emptyFunc,
  offset = 0,
  throttleMilliseconds = 100
): [React.RefObject<Element>] {
  const currentElement = createRef<Element>()

  const onScroll = _throttle(() => {
    if (!currentElement.current) return
    const top = currentElement.current.getBoundingClientRect().top
    const isVis = top + offset >= 0 && top - offset <= window.innerHeight
    if (typeof callback === 'function' && isVis) callback()
  }, throttleMilliseconds)

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return (): void => window.removeEventListener('scroll', onScroll)
  }, [onScroll, currentElement])

  return [currentElement]
}
